@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.login-background {
    background: linear-gradient(9.11deg, rgba(36, 133, 255, 0.8) 6.91%, #1B7FFD 93.09%);
}

.ant-btn-primary {
    background-color: #1677ff !important;
}

.ant-drawer {
    z-index: 1100;
}

.sidebar-icon-style {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.ant-tabs .ant-tabs-ink-bar {
    background: #37352F;
}

.logout-custom .ant-menu-item {
    background-color: #667A8A;
}

.logout-custom .ant-menu-item.ant-menu-item-selected {
    background-color: #667A8A;
}

.logout-custom .ant-menu-item:hover {
    background-color: #667A8A !important;
}

.title-workspace .ant-menu-submenu-title {
    display: flex;
    align-items: center;
}

.ant-select-selection-item-remove{
    display: flex !important;
}

.medium-editor-element{
    min-height: 300px !important;
}

.custom-width-full{
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
}

.ant-switch{
    background-color: rgba(0, 0, 0, 0.45);
}

.ql-editor {
    font-size: 16px;
}

.ql-tooltip {
    left: 0 !important;
}

.top-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
    background-color: #fff;
}

.tooltip-menu {
    opacity: 1!important;
    z-index: 2001;
}

.tool-primary-btn {
    background-color: #1B7FFD;
}

.tooltip-space {
    background-color: #333;
    padding: 3px;
}

.tooltip-text {
    color: #eee;
    font-weight: bold;
}

.highlight {
    background-color: yellowgreen;
}

.ant-select-dropdown{
    z-index: 99999999;
}

